<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <!-- 搜索 -->
        <div class="search-box">
          <div class="ayl-item" style="width: 280px">
            <span>运输日期</span>
            <el-date-picker
              class="item"
              v-model="date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :picker-options="pickerOptions"
              @change="queryChange"
            >
            </el-date-picker>
          </div>
          <div class="ayl-item" style="width: 200px">
            <span>业务类型</span>
            <el-select
              class="item"
              v-model="query.orderType"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 200px">
            <span>订单状态</span>
            <el-select
              class="item"
              v-model="query.orderStatus"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 220px">
            <el-input
              placeholder="客户名称/起运地/目的地/省市区"
              v-model="query.queryContent"
              clearable
              @input="queryChange"
            >
            </el-input>
          </div>
          <el-button
            style="margin-right: 20px"
            type="primary"
            size="mini"
            @click="onSearch"
            >查询</el-button
          >
          <el-link type="primary" style="line-height: 28px" @click="clickExport"
            >查询数据导出</el-link
          >
        </div>
        <!-- 表格 -->
        <div class="table-box">
          <el-table
            v-loading="loading"
            :header-cell-style="getRowClass"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="partnerName"
              show-overflow-tooltip
              label="客户名称"
            >
              <template slot-scope="scope">{{
                scope.row.partnerName || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="partnerAccountName"
              show-overflow-tooltip
              label="发货人"
            >
              <template slot-scope="scope">{{
                scope.row.senderName || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="transportTime"
              width="100px"
              show-overflow-tooltip
              label="运输日期"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.transportTime">- -</span>
                <span v-else>{{ scope.row.transportTime | str2ymd }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="plateNumber"
              width="100px"
              show-overflow-tooltip
              label="提货车号"
            >
              <template slot-scope="scope">{{
                scope.row.plateNumber || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="carBelongDes"
              show-overflow-tooltip
              label="车辆归属"
            >
              <template slot-scope="scope">{{
                scope.row.carBelongDes || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="driverName"
              width="100px"
              show-overflow-tooltip
              label="驾驶员"
            >
              <template slot-scope="scope">{{
                scope.row.driverName || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="orderType"
              align="center"
              width="100px"
              show-overflow-tooltip
              label="业务类型"
            >
              <template slot-scope="scope">
                <span
                  :style="{
                    color:
                      scope.row.orderType == '直拨'
                        ? $config.color_z
                        : scope.row.orderType == '内转'
                        ? $config.color_n
                        : $config.color_w,
                  }"
                >
                  {{ scope.row.orderType || "- -" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sendAddressDetail"
              width="200px"
              show-overflow-tooltip
              label="起运地"
            >
              <template slot-scope="scope">{{
                scope.row.sendAddressDetail || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="receiveProvinceName"
              width="200px"
              show-overflow-tooltip
              label="收货地（省市区）"
            >
              <template slot-scope="scope">{{
                scope.row.receiveProvinceName || "- -"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="receiveAddressDetail"
              width="200px"
              show-overflow-tooltip
              label="目的地"
            >
              <template slot-scope="scope">{{
                scope.row.receiveAddressDetail || "- -"
              }}</template>
            </el-table-column>

            <el-table-column
              prop="orderStatus"
              width="100px"
              show-overflow-tooltip
              label="订单状态"
            ></el-table-column>
            <el-table-column
              prop="orderId"
              width="150px"
              show-overflow-tooltip
              label="订单编号"
            ></el-table-column>
            <el-table-column
              prop="imageNumber"
              width="100px"
              align="center"
              show-overflow-tooltip
              label="图片"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.imageNumber > 0"
                  type="text"
                  size="small"
                  @click="orderImageList(scope.row)"
                  >有</el-button
                >
                <el-button v-else disabled type="text" size="small"
                  >无</el-button
                >
              </template>
            </el-table-column>

            <el-table-column
              label="操作"
              width="80"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="orderDetails(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px; text-align: right">
          <el-pagination
            @size-change="mainSizeChange"
            @current-change="mainCurrentChange"
            :current-page.sync="mainCurrentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNums"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 预览图片 -->
    <el-dialog
      title="图片列表"
      :visible.sync="imageListDialogVisible"
      width="1000px"
      center
      :before-close="handleClose">
        <div class="content-main">
            <!--图片-->
          <div class="order-img" :style="{'margin-right': (idx+1)%3===0?'0px':''}"
              v-for="(item, idx) in big_imgList" :key="idx">
            <el-image
                    :preview-src-list="imgSrcList"
                    :src="big_images[idx]"
                    fit="cover" style="width: 100%;height: 100%;border-radius: 4px"
                    @click="onPreview(idx)"
                    lazy
                    ></el-image>
            <div class="img-text">
              <div>
                <span>{{item.userName||'- -'}}</span>
                &nbsp;
                <span>{{item.uploaderPhone||'- -'}}</span>
              </div>
              <span>{{item.gmtCreate|str2ymdhm}}</span>
            </div>
            <div class="img-icon">
              {{item.imageType}}
            </div>
            <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i>
          </div>

          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            layout="total, prev, pager, next, jumper"
            :total="totalNum"
            >
          </el-pagination>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import mixins from '../../utils/mixins'
export default {
  mixins: [mixins.$exportCheckAll],
  data() {
    return {
      nav: [{ name: "订单查询" }],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: [
        new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        new Date().getTime(),
      ],
      options: this.$enums.OrderType.list,
      options1: this.$enums.OrderStatus.list,

      query: {
        type: 0,
        orderType: null,
        carBlong: null,
        orderStatus: null,
        begin: new Date().setTime(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        ),
        end: new Date().getTime(),
        queryContent: null,
        currentPage: 1,
        pageSize: 10,
      },

      tableData: [],
      loading: true,
      mainCurrentPage: 1,
      totalNums: 0,

      imageListDialogVisible: false,
      imageInfoVOS: [],
      allImageInfoVOS: [],
      totalNum: 0,
      currentPage: 1,
      pageSize: 9,
      dialogExport: false,
      checkList: [],
      checkIndex: [],
      btnLoading: false,

      counst: 0,
      bigImgList:[],
      bigImages:[],
      big_imgList:[],
      big_images:[],
      imgSrcList:[],
    };
  },

  watch: {
    query: {
      deep: true,
      handler: function (v, ov) {
        this.getDate(this.query);
      },
    },
    date: {
      deep: true,
      handler: function (v, ov) {
        this.query.begin = this.date ? this.date[0] : null;
        this.query.end = this.date ? this.date[1] : null;
      },
    },
  },
  methods: {
    onSearch() {
      this.getDate(this.query);
    },
    mainSizeChange(val) {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
      this.query.pageSize = val;
    },
    mainCurrentChange(val) {
      this.query.currentPage = val;
    },
    queryChange() {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#FAFAFA;color:#262626;font-weight:600;";
      } else {
        return "";
      }
    },
    // 获取列表数据
    async getDate(val) {
      this.loading = true;
      let data = await this.$api.OrderList(val);
      this.tableData = data.data;
      this.totalNums = data.totalNum;
      this.loading = false;
    },
    clickExport() {
      this.dialogExport = true;
      this.$api
        .GetExcelExportKeyAndColumn({
          excelKey: "SteelOrder",
        })
        .then((res) => {
          this.checkList = res.chsList;
          this.checkIndex = this.checkList.map((e) => {
            return e.index;
          });
        });
    },
    // 依次查看大图
    onPreview(index) {
      let arr1 = this.big_images.filter(
        (arrItem, arrIndex) => index <= arrIndex
      );
      let arr2 = this.big_images.filter(
        (arrItem, arrIndex) => index > arrIndex
      );
      let arr3 = arr2.sort((a, b) => {
        b - a;
      });
      this.imgSrcList = [...arr1, ...arr3];
    },

    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.reportExcel({
          excelKey: "SteelOrder",
          indexList: this.checkIndex,
          params: {
            startTime: this.query.begin,
            endTime: this.query.end,
            orderType: this.query.orderType,
            orderStatus: this.query.orderStatus,
            queryContent: this.query.queryContent,
          },
        });
        window.open(url);
        this.dialogExport = false;
        // eslint-disable-next-line no-empty
      } catch (e) {}
      this.btnLoading = false;
    },
    /**
     * 录单
     */
    orderDetails(val) {
      this.$router.push({
        path: "/search-order/order-details",
        query: { orderId: val.orderId, orderStatus: val.orderStatus },
      });
    },
    /**
     * 显示订单图片列表
     */
    orderImageList(val) {
      this.imageListDialogVisible = true;
      this.bigImgList = [];
      this.bigImages = [];
      for (let item of val.imageInfoVOS) {
        if (Array.isArray(item.imageUrl) && item.imageUrl.length >= 2) {
          for (let i of item.imageUrl) {
            this.bigImages.push(i);
            this.bigImgList.push({
              gmtCreate: item.gmtCreate,
              gmtModified: item.gmtModified,
              imageRemark: item.imageRemark,
              imageType: item.imageType,
              imageUrl: i,
              orderId: item.orderId,
              tenantId: item.tenantId,
              uploaderPhone: item.uploaderPhone,
              userIdentity: item.userIdentity,
              userName: item.userName,
            });
          }
        } else {
          let img = Array.isArray(item.imageUrl)
            ? item.imageUrl[0]
            : item.imageUrl;
          this.bigImages.push(img);
          this.bigImgList.push(item);
        }
      }
      this.totalNum = this.bigImgList.length;
      this.big_images = this.bigImages.filter(
        (item, index) => index < this.pageSize
      );
      this.big_imgList = this.bigImgList.filter(
        (item, index) => index < this.pageSize
      );
    },
    handleClose() {
      this.imageListDialogVisible = false;
    },
    /**
     * 图片分页
     */
    handleCurrentChange(val) {
      this.big_images = [];
      this.big_imgList = [];

      this.big_images = this.bigImages.filter((item, index) => {
        if ((val - 1) * this.pageSize <= index && index < this.pageSize * val) {
          return item;
        }
      });
      this.big_imgList = this.bigImgList.filter((item, index) => {
        if ((val - 1) * this.pageSize <= index && index < this.pageSize * val) {
          return item;
        }
      });
    },
  },
  mounted() {
    this.getDate(this.query);
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/search-order/order-details") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  async activated() {
    if (!this.$route.meta.isBack) {
      this.counst++;
    } else {
      await this.getDate(this.query);
      this.$route.meta.isBack = false; // 重置详情页标识isBack
    }
  },
};
</script>

<style lang='sass' scoped>
.ayl-item
  display: flex;
  align-content: center;
  margin-right: 20px;
  width: 20%;
  max-width: 300px;
.ayl-item > span 
  margin-right: 10px;
  line-height: 28px;
.item 
  flex: 1;
.el-button--mini 
  height: 28px;

.order-img
  position: relative
  width: calc((100% - 2 * 13px) / 3)
  height: 138px
  display: inline-block
  margin-right: 13px
  margin-bottom: 14px
  border-radius: 4px
.img-text
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  height: 36px
  background: rgba(0, 0, 0, 0.6)
  color: white
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0 10px
  font-size: 12px
.img-icon
  position: absolute
  top: 0
  right: 0
  width: 36px
  height: 36px
  line-height: 30px
  text-align: center
  color: white
  font-size: 12px
  background: url("../../assets/images/img-icon.png") no-repeat 100% / cover
.close-icon
  position: absolute
  top: -5px
  right: -8px
  font-size: 14px
  cursor: pointer
/deep/ .el-image-viewer__close
   color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
   border: 3px solid #fff
</style>